.preloader-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .dot {
    display: inline-block;
    background: #3b3b3b;
    height: 10px;
    width: 10px;
    opacity: 0.5;
    border-radius: 50%;
    animation: up-and-down 1.4s infinite;

    &:nth-child(2) {
      animation-delay: 0.1s;
    }

    &:nth-child(3) {
      animation-delay: 0.2s;
    }

    &:nth-child(4) {
      animation-delay: 0.3s;
    }

    &:nth-child(5) {
      animation-delay: 0.4s;
    }
  }

  @keyframes up-and-down {
    0% {
      transform: translateY(0px);
    }

    35% {
      transform: translateY(0px);
      opacity: 0.5;
    }

    50% {
      transform: translateY(-5px);
      opacity: 1;
    }

    70% {
      transform: translateY(2px);
      opacity: 0.6;
    }

    85% {
      transform: translateY(0px);
    }
  }
}
