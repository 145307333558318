.main-container {
  padding-block: 60px;
  padding-inline: 5px;
  position: relative;

  .main-tt {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 111;
    padding: 10px 15px;
    max-width: 768px;
    margin: 0 auto;
    // backdrop-filter: saturate(180%) blur(4px);

    &.active {
      transition: 0.2s ease-in-out;
      box-shadow: 0 4px 30px #0000000f;
      background: rgb(255 255 255 / 90%);
    }
    &.inActive {
      transition: 0.2s ease-in-out;
      box-shadow: 0 4px 30px #00000000;
      background: #4ba0c100;
    }
    .main-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo-img {
        width: 114px;
      }
    }
    .category-title {
      display: flex;
      justify-content: space-between;

      .category-title-input {
        border-radius: 10px;
        height: 40px;
        width: 250px;
        padding-inline: 10px;
        font-size: 20px;
        font-weight: 600;
        background: transparent;
        border: 3px solid #002eb5;
        color: #000000;
        &::placeholder {
          color: #000000;
        }
        &:focus {
          border: 3px solid #000000;
          background: #ffffffca;
        }
      }
      h2 {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .basket {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 50%;
        border: 3px solid #002eb5;
        .basket-img {
          width: 20px;
          fill: #002eb5;
        }
        .div {
          display: none;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          right: 0px;
          background-color: #ff1500;
        }
        &.active {
          .div {
            display: block;
          }
        }
      }
    }
  }
  .bottom-btns {
    z-index: 100;
    position: fixed;
    padding: 10px;
    padding-top: 15px;
    background-color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    max-width: 768px;
    margin: 0 auto;

    .bottom-btn-1 {
      border: none;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      gap: 7px;
      width: 48%;
      background-color: #ffd000;
      box-shadow: 0px 4px 0px 0px #ffd000;
      transform: translateY(-4px);
      transition: all 0.05s ease;
      cursor: pointer;
    }
    .bottom-btn-2 {
      border: none;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      gap: 7px;
      background-color: #006631;
      box-shadow: 0px 4px 0px 0px #024614;
      transform: translateY(-4px);
      transition: all 0.05s ease;
      cursor: pointer;
      width: 48%;

      &:active {
        transform: translateY(0px);
        box-shadow: none;
      }
    }
  }
  .main-boby {
    .category-items {
      display: grid;
      padding-bottom: 20px;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 549px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 375px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 320px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .category-item {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        gap: 10px;

        .category-item-img {
          display: flex;
          justify-content: center;
          align-items: center;
          aspect-ratio: 3 / 4;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            border-radius: 4px;
          }
        }
        .category-item-info {
          display: flex;
          flex-direction: column;
          gap: 7px;
          margin-top: 10px;
          margin-bottom: 7px;
          padding-inline: 3px;
          color: #1c1c1c;
          h2 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;

            font-size: 17px;
            font-weight: 600;
            line-height: 1.3;
          }
          h3 {
            font-size: 15px;
            font-weight: 600;
            line-height: 1.3;
            letter-spacing: 0em;
          }
          h4 {
            font-size: 10px;
            font-weight: 400;
            line-height: 1.3;
            letter-spacing: 0em;
          }
        }
        .category-item-add {
          padding: 12px;
          cursor: pointer;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          font-size: 16px;
          font-weight: 700;
          color: #fff;
          gap: 7px;
          background-color: #006631;
          box-shadow: 0px 4px 0px 0px #024614;
          transform: translateY(-4px);
          transition: all 0.05s ease;
          cursor: pointer;

          &:active {
            transform: translateY(0px);
            box-shadow: none;
          }
          img {
            width: 15px;
          }
        }
      }
    }
  }
}
.add-popup {
  padding: 15px;
  position: relative;
  .add-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    cursor: pointer;
  }
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    .item-image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 130px;
      width: 130px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item-info {
      width: 250px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      .item-info-div {
        display: flex;
        flex-direction: column;
        gap: 7px;
        font-weight: 400;
        font-size: 15px;
        line-height: 1.3;

        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 1.3;
        }
        b {
        }
        p {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}
.basket-popup {
  background-color: #fff;
  padding: 15px;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  position: relative;
  min-height: 300px;
  .basket-popup-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 15px;
    cursor: pointer;
  }
  .basket-popup-h2 {
  }
  .basket-popup-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
    .basket-popup-item {
      display: flex;
      justify-content: space-between;
      padding: 7px;
      border: 2px solid #002eb5;
      border-radius: 10px;
      .span-1 {
        font-size: 15px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: 0em;
        overflow: hidden;
        width: 10ch;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .span-2 {
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        align-items: center;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          margin-left: 5px;
          width: 15px;
        }
      }
    }
  }
  .basket-popup-btn {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    border: none;
    font-size: 18px;
    font-weight: 600;
    background-color: #006631;
    box-shadow: 0px 4px 0px 0px #024614;
    transform: translateY(-4px);
    transition: all 0.05s ease;
    cursor: pointer;

    &:active {
      transform: translateY(0px);
      box-shadow: none;
    }
  }
}
.basket-popup-empty {
  padding: 15px;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.add-basket-popup {
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;

  .add-close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      position: absolute;
      top: -18px;
      cursor: pointer;
      height: 5px;
    }
  }
  .add-basket-popup-container {
    display: grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    .item-image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
    .add-basket-popup-info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .item-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        .item-info-div {
          display: flex;
          flex-direction: column;
          gap: 7px;
          font-weight: 400;
          font-size: 15px;
          line-height: 1.3;

          span {
            font-weight: 400;
            font-size: 15px;
            line-height: 1.3;
          }

          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
      .popup-add-bottom {
        .count {
          display: flex;
          // flex-direction: column;
          align-items: center;
          // justify-content: center;
          gap: 5px;
          width: 100%;
          margin-block: 10px;
          .count-1 {
            font-size: 20px;
            font-weight: 700;
          }
          .count-2 {
            font-size: 18px;
            font-weight: 700;
          }
        }
        .item-btns {
          display: flex;
          justify-content: space-between;
          .add {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48%;
            background: #0e371f;
            border-radius: 10px;
            padding: 10px;
            color: #fff;
            font-size: 20px;
            vertical-align: middle;
            background-color: #006631;
            box-shadow: 0px 4px 0px 0px #024614;
            transform: translateY(-4px);
            transition: all 0.05s ease;
            cursor: pointer;

            &:active {
              transform: translateY(0px);
              box-shadow: none;
            }
          }
          .close {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48%;
            border: 2px solid #024614;
            border-radius: 10px;
            padding: 10px;
            box-shadow: 0px 4px 0px 0px #024614;
            transform: translateY(-4px);
            transition: all 0.05s ease;
            cursor: pointer;

            &:active {
              transform: translateY(0px);
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .item-btn-add-to-bsk-btns {
    display: flex;
    justify-content: space-between;
    .item-btn-add-to-bsk {
      padding: 10px;
      border-radius: 10px;
      width: 49%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-top: 10px;
      cursor: pointer;
      background-color: #e30000;
      transform: translateY(-4px);
      transition: all 0.05s ease;
      cursor: pointer;
      box-shadow: 0px 4px 0px 0px #af0202;

      &:active {
        transform: translateY(0px);
        box-shadow: none;
      }
    }
    .item-btn-add-to-bsk-2 {
      padding: 10px;
      border-radius: 10px;
      width: 49%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-top: 10px;
      cursor: pointer;
      background-color: #006631;
      transform: translateY(-4px);
      transition: all 0.05s ease;
      cursor: pointer;
      box-shadow: 0px 4px 0px 0px #024614;

      &:active {
        transform: translateY(0px);
        box-shadow: none;
      }
    }
  }
}

.category-cards {
  padding-right: 10px;
  margin-top: 15px;

  .category-card {
    margin: 5px;
    padding: 10px;
    white-space: nowrap;
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 100px;
    height: 100%;
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0);
    border-color: #002eb5;
    color: #002eb5;
    background-color: transparent;

    box-shadow: 0px 3px 0px 0px #000000;
    transform: translateY(-4px);
    transition: all 0.05s ease;
    cursor: pointer;

    &.active {
      border-color: transparent;
      color: #eee;
      background-color: #002eb5;
      box-shadow: 0px 2px 0px 0px #000000;
    }

    .category-card-img {
      width: 47px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }

    p {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  }
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
