* {
  -moz-text-size-adjust: none !important;
  -webkit-text-size-adjust: none !important;
  text-size-adjust: none !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  font-family: semiBold, sans-serif; // this is a default font family

  -webkit-tap-highlight-color: transparent;
  // outline: 1px solid red;
}

html {
  background-color: #fff;
}

body {
  padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px)
    env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px);
}
